import React from "react";
import { Button } from "@mui/material";
import { useMutation } from "react-query";
import { useDataProvider } from "react-admin";
import { DeleteForever } from "@mui/icons-material";

const MarkMessageDeletedButton = ({ messageId }) => {
  const dataProvider = useDataProvider();

  const { mutate, isLoading } = useMutation(["markMessageDeleted", messageId], () =>
    dataProvider.markMessageDeleted({ id: messageId })
  );

  const handleClick = () => {
    const userConfirmed = window.confirm("Are you sure you want to mark this message as DELETED?");
    if (userConfirmed) {
      mutate();
    }
  };

  return (
    <Button color="error" variant="outlined" startIcon={<DeleteForever/>} onClick={handleClick} disabled={isLoading}>
      Mark Deleted
    </Button>
  );
};

export default MarkMessageDeletedButton;
