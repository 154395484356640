import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  TextInput,
  NullableBooleanInput,
  useRecordContext,
  Link,
  ReferenceField,
  required,
  Edit,
  TabbedForm,
  FormTab,
  BooleanInput,
  Labeled,
  ReferenceManyField,
  Pagination,
  FunctionField,
  BulkDeleteButton,
  Button as RAButton,
  ArrayField,
  SimpleShowLayout,
  useListContext,
  useNotify,
} from "react-admin";

import LinkIcon from "@mui/icons-material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";

import MergeIcon from "@mui/icons-material/Merge";
import {
  Avatar,
  Typography,
  Tooltip,
  Badge,
  CircularProgress,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MUIButton,
  Box,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { WorkspaceLinkField } from "./workspaces";
import { ChannelLinkField, ChannelLinkIdField } from "./channels";
import { toHHMMSS, MessageLinkIdField } from "./messages";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import this icon
import { useDataProvider } from "react-admin";

const StyledVerifyButton = styled(RAButton)({
  backgroundColor: "#6200EA", // Purple background color
  color: "#FFFFFF", // White text color
  "&:hover": {
    backgroundColor: "#7b1fa2", // Slightly darker purple on hover
    color: "#FFFFFF", // Ensure text color remains white on hover
  },
});

const VerifyUserButton = () => {
  const record = useRecordContext();
  const [isVerified, setIsVerified] = useState(record.is_verified);
  const notify = useNotify();

  const handleVerifyUser = async () => {
    const headers = {
      pxtoken: localStorage.getItem("pxtoken"),
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/user/set_verified/${record.id}`,
        {},
        { headers }
      );
      if (response.status === 200) {
        setIsVerified(true);
        notify("User verified successfully!", "info");
      }
    } catch (error) {
      console.error("Verification failed:", error);
      notify("Failed to verify user.", "warning");
    }
  };

  if (isVerified) {
    return (
      <Typography
        style={{
          color: "green",
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
        }}
      >
        User Verified{" "}
        <CheckCircleIcon style={{ marginLeft: "5px", color: "green" }} />
      </Typography>
    );
  } else {
    return <StyledButton onClick={handleVerifyUser}>Verify User</StyledButton>;
  }
};

const ContactUserIdsList = () => {
  const { id } = useParams(); // Access the 'id' parameter from the URL
  const [contactDetails, setContactDetails] = React.useState([]);

  React.useEffect(() => {
    const fetchContactUserIds = async () => {
      try {
        const headers = {
          pxtoken: localStorage.getItem("pxtoken"),
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/contact-ids-for-user/${id}`,
          { headers }
        );
        if (response.data.length > 0) {
          fetchContactDetails(response.data);
        } else {
          return setContactDetails([]);
        }
      } catch (error) {
        console.error("Error fetching contact user IDs:", error);
      }
    };

    const fetchContactDetails = async (userIds) => {
      try {
        const headers = {
          pxtoken: localStorage.getItem("pxtoken"),
        };
        const body = {
          query: null,
          user_guids: userIds, // Use the user IDs from the last result
          last_updated: null,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/contacts`,
          body,
          { headers }
        );

        // Check if response data is empty and clear state if so
        if (response.data.length === 0) {
          setContactDetails([]);
        } else {
          setContactDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching contact details:", error);
      }
    };

    if (id) {
      fetchContactUserIds();
    }
  }, [id]);

  const maxColumns = 5;
  const contactsPerColumn = Math.ceil(contactDetails.length / maxColumns);
  const numColumns = Math.min(
    Math.ceil(contactDetails.length / contactsPerColumn),
    maxColumns
  ); // Limit the number of columns to maxColumns
  const columns = Array.from({ length: numColumns }, (_, i) =>
    contactDetails.slice(i * contactsPerColumn, (i + 1) * contactsPerColumn)
  );

  return (
    <div>
      <Typography
        variant="h6"
        style={{ marginBottom: "16px", fontSize: "20px" }}
      >
        Contact Details ({contactDetails.length})
      </Typography>
      {contactDetails.length > 0 ? (
        <Grid container spacing={2}>
          {columns.map((columnContacts, columnIndex) => (
            <Grid item key={columnIndex} xs={12 / numColumns}>
              {columnContacts.map((contact) => (
                <div key={contact.user_guid} style={{ paddingBottom: "10px" }}>
                  <UserLinkField
                    record={{
                      id: contact.user_guid,
                      first_name: contact.first_name,
                      last_name: contact.last_name,
                      image_url: contact.image_url,
                    }}
                    style={{ fontSize: "16px" }}
                  />
                </div>
              ))}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1">No Contacts!</Typography>
      )}
    </div>
  );
};

const TrainUserVoiceForm = ({ user_id, onClose, ...props }) => {
  const [messageId, setMessageId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const record = useRecordContext();

  const handleTrainUserVoice = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Adding the "pxtoken" header
    const headers = {
      pxtoken: localStorage.getItem("pxtoken"),
    };

    try {
      setLoading(true); // Set loading to true when starting the API call

      const url = `${process.env.REACT_APP_API_BASE_URL}/user/${record.id}/voice/elevenlabs/train`;
      const queryParams = messageId ? `?message_id=${messageId}` : "";

      await axios.put(`${url}${queryParams}`, null, { headers });

      setSuccess(true); // Set success to true when the API call is successful
    } catch (error) {
      console.error("Error training user voice:", error);
    } finally {
      setLoading(false); // Set loading back to false when the API call is complete
    }

    if (typeof onClose === "function") {
      onClose(); // Close the form after submission
    }
  };

  return (
    <div>
      <div>
        <Divider style={{ margin: "16px 0" }} />{" "}
        {/* Horizontal divider with margin */}
        <Typography variant="h6" gutterBottom>
          Voice Training ( Voice ID: {record.voice_id || "Not Trained"})
        </Typography>
      </div>
      <div>
        <StyledTextInput
          source="message_id"
          label="Message ID (Optional)"
          value={messageId}
          onChange={(e) => setMessageId(e.target.value)}
        />
      </div>
      <div>
        <StyledButton
          label={success ? "Voice Trained!" : "Train Voice"}
          onClick={handleTrainUserVoice}
          disabled={loading || success} // Disable the button when loading or already successful
        >
          {loading && <CircularProgress size={20} color="inherit" />}{" "}
          {/* Show spinner when loading */}
        </StyledButton>
        <Divider style={{ margin: "16px 0" }} />{" "}
        {/* Horizontal divider with margin */}
      </div>
    </div>
  );
};

const StyledTextInput = styled(TextInput)({
  marginRight: "16px", // Adjust the spacing between the TextInput and Button
});

const StyledButton = styled(RAButton)({
  backgroundColor: "#6200EA", // Purple background color
  color: "#FFFFFF", // White text color
});

const userFilters = [
  <TextInput label="First Name" source="first_name" alwaysOn />,
  <TextInput label="Last Name" source="last_name" alwaysOn />,
  <TextInput label="Email" source="identities.provider_email" alwaysOn />,
  <TextInput label="ID" source="_id" alwaysOn />,
  <TextInput
    label="Phone Number"
    source="identities.provider_phone"
    alwaysOn
  />,
  <NullableBooleanInput helperText={false} source="is_verified" />,
];

export const AvatarField = ({ size = "25", sx, record }) => {
  const contextRecord = useRecordContext();
  const finalRecord = record || contextRecord;
  if (!finalRecord) return null;

  const avatarStyle = {
    width: parseInt(size, 10),
    height: parseInt(size, 10),
    backgroundColor: finalRecord.image_url ? "transparent" : "#e0e0e0", // Light grey background if no image
    color: finalRecord.image_url ? "inherit" : "black", // Black text color if no image
    fontSize: "1rem", // Adjust font size as needed
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Tooltip title={`${finalRecord.first_name} ${finalRecord.last_name}`}>
      <Avatar src={finalRecord.image_url} style={avatarStyle} sx={sx}>
        {!finalRecord.image_url && finalRecord.first_name
          ? finalRecord.first_name[0]
          : ""}
      </Avatar>
    </Tooltip>
  );
};

const formatTime = (datetimeString) => {
  const dateValue = new Date(datetimeString);
  return dateValue.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const CustomDateField = ({ source, label }) => {
  const record = useRecordContext();
  const dateValue = record[source];

  return (
    <Tooltip title={formatTime(dateValue)}>
      <Typography variant="body2">
        <DateField record={record} source={source} label={label} />
      </Typography>
    </Tooltip>
  );
};

export default CustomDateField;

export const FullNameField = ({ size, record }) => {
  const contextRecord = useRecordContext();
  const finalRecord = record || contextRecord;

  if (!finalRecord) {
    return null;
  }

  const online =
    finalRecord.last_seen_on &&
    new Date(finalRecord.last_seen_on).getTime() >
      new Date().getTime() - 1000 * 60 * 15;

  return (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
    >
      {online ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <AvatarField
            record={finalRecord}
            size={size}
            sx={{
              mr: 1,
              mt: -0.5,
              mb: -0.5,
            }}
          />
        </StyledBadge>
      ) : (
        <AvatarField
          record={finalRecord}
          size={size}
          sx={{
            mr: 1,
            mt: -0.5,
            mb: -0.5,
          }}
        />
      )}
      {finalRecord.first_name} {finalRecord.last_name}
    </Typography>
  );
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 3s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const UserLinkField = ({ record, goblank }) => {
  const contextRecord = useRecordContext();
  const finalRecord = record || contextRecord; // Use the passed record if available, otherwise use the context record

  if (!finalRecord) {
    return null;
  }

  return (
    <Link to={`/users/${finalRecord.id}`} target={goblank ? "_blank" : "_self"}>
      <FullNameField record={finalRecord} />
    </Link>
  );
};

const BulkActionButtons = () => {
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const [direction, setDirection] = useState("forward");
  const { selectedIds, data } = useListContext();
  const [orderedIds, setOrderedIds] = useState(selectedIds);

  const handleOpenMergeModal = () => {
    setIsMergeModalOpen(true);
  };

  const handleResetState = () => {
    setDirection("forward");
    setOrderedIds(selectedIds); // Reset to the original order
  };

  const handleMergeAccounts = async () => {
    const headers = {
      pxtoken: localStorage.getItem("pxtoken"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/users/merge/`,
        {
          // Include any required data for the merge operation, such as the user IDs
          user_ids: orderedIds,
        },
        { headers }
      );

      console.log("Merge successful:", response.data);
      // Handle success (e.g., show a success message, update the UI, etc.)
    } catch (error) {
      console.error("Error merging accounts:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const selectedData = data.filter((user) => selectedIds.includes(user._id));

  return (
    <>
      <RAButton
        startIcon={<MergeIcon />}
        label="Merge"
        onClick={handleOpenMergeModal}
      />
      <BulkDeleteButton />
      <MergeModal
        open={isMergeModalOpen}
        onClose={() => setIsMergeModalOpen(false)}
        selectedUserIds={selectedIds}
        selectedData={selectedData}
        direction={direction}
        setDirection={setDirection}
        orderedIds={orderedIds}
        setOrderedIds={setOrderedIds}
        onResetState={handleResetState}
        handleMergeAccounts={handleMergeAccounts} // Pass handleMergeAccounts to the MergeModal
      />
    </>
  );
};

const MergeModal = ({
  open,
  onClose,
  selectedUserIds,
  selectedData,
  direction,
  setDirection,
  orderedIds,
  setOrderedIds,
  onResetState,
}) => {
  const [mergeSuccess, setMergeSuccess] = useState(false);

  const handleArrowClick = () => {
    setDirection(direction === "forward" ? "backward" : "forward");
    setOrderedIds([...orderedIds].reverse()); // Create a new array to avoid mutating the state directly
  };

  const handleMergeAccounts = async () => {
    // Perform the merge operation here
    try {
      const headers = {
        pxtoken: localStorage.getItem("pxtoken"),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/users/merge/`,
        {
          from_user_guid: orderedIds[0],
          into_user_guid: orderedIds[1],
        },
        { headers }
      );
      if (response.status === 201) {
        setMergeSuccess(true);
      }
    } catch (error) {
      console.error("Merge failed:", error);
    }
  };

  const handleClose = () => {
    if (mergeSuccess) {
      setMergeSuccess(false); // Reset the merge success state
      onResetState(); // Reset other states
    }
    onClose();
  };

  useEffect(() => {
    if (!open) {
      onResetState();
    }
  }, [open, onResetState]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Merge Users</DialogTitle>
      <DialogContent>
        {mergeSuccess ? (
          <div>
            <Typography variant="h6">Merge Successful!</Typography>
            <Typography>
              Account merged from {orderedIds[0]} to {orderedIds[1]}
            </Typography>
          </div>
        ) : (
          <Grid container spacing={3} alignItems="center">
            {selectedData.map((user, idx) => (
              <React.Fragment key={user.id}>
                <Grid item xs={5}>
                  <Box border={1} p={3}>
                    <Box mb={2.5}>
                      <UserLinkField
                        goblank={true}
                        record={{
                          id: user.id,
                          first_name: user.first_name,
                          last_name: user.last_name,
                          image_url: user.image_url,
                        }}
                        style={{ fontSize: "28px" }}
                      />
                      <Typography variant="caption" display="block">
                        {user._id}{" "}
                        {user.is_verified ? (
                          <CheckIcon
                            style={{ color: "green", fontSize: "16px" }}
                          />
                        ) : (
                          <CloseIcon
                            style={{ color: "red", fontSize: "16px" }}
                          />
                        )}
                      </Typography>
                    </Box>
                    <Typography variant="caption" display="block">
                      Identities:
                    </Typography>
                    {user.identities.map((identity, index) => (
                      <Typography key={index} variant="caption" display="block">
                        {identity.first_name} {identity.last_name}{" "}
                        {identity.provider_email ||
                          identity.provider_phone ||
                          "N/A"}{" "}
                        {identity.is_verified ? (
                          <CheckIcon
                            style={{ color: "green", fontSize: "16px" }}
                          />
                        ) : (
                          <CloseIcon
                            style={{ color: "red", fontSize: "16px" }}
                          />
                        )}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
                {idx < selectedData.length - 1 && (
                  <Grid
                    item
                    xs={2}
                    container
                    justifyContent="center"
                    onClick={handleArrowClick}
                  >
                    {direction === "forward" ? (
                      <ArrowForwardIcon />
                    ) : (
                      <ArrowBackIcon />
                    )}
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        {mergeSuccess ? (
          <MUIButton onClick={handleClose}>Close</MUIButton>
        ) : (
          <>
            <MUIButton onClick={handleClose}>Cancel</MUIButton>
            <MUIButton onClick={handleMergeAccounts}>Merge</MUIButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

// UserList Component
export const UserList = (props) => {
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);

  const handleOpenMergeModal = () => {
    setIsMergeModalOpen(true);
  };

  const handleCloseMergeModal = () => {
    setIsMergeModalOpen(false);
  };

  return (
    <List {...props} filters={userFilters}>
      <Datagrid rowClick="edit" bulkActionButtons={<BulkActionButtons />}>
        <UserLinkField />

        <ReferenceField label="Links" source="id" reference="users">
          <LinkTo platform="Amplitude" icon={true} />
          <LinkTo platform="Sentry" icon={true} />
        </ReferenceField>

        <BooleanField source="is_verified" />
        <BooleanField source="is_allowed_through_gate" />

        <ReferenceField source="created_by" reference="users">
          <UserLinkField />
        </ReferenceField>

        <TextField source="role" />
        <TextField source="plan_type" />
        <CustomDateField source="deleted_on" label="Deleted On" />
        <CustomDateField source="last_seen_on" label="Last Seen" />
        <CustomDateField source="created_at" label="Created At" />
      </Datagrid>
    </List>
  );
};

const NotificationSettingsList = (props) => {
  const record = useRecordContext(); // Assuming this provides your record data

  const NotificationsType = {
    DAILY_DIGEST: "Daily Digest",
    CONV_ADDED_NAMED: "Named Conversation Added",
    MSG_NEW: "New Message",
    EMOTICON_RSP: "Emoticon Response",
    MEET_NEW: "New Meeting",
    MEET_UPDATE_CREATOR: "Meeting Creator Update",
    MEET_UPDATE_MEMBER: "Meeting Update Creator",
    MEET_ENDED: "Meeting Ended",
    MEET_JOINED: "Meeting Joined",
    WS_INVITE: "Workspace Invite",
    WS_JOINED: "Workspace Joined",
    WS_ADDED: "Workspace Added",
  };

  const getPrettyNotificationName = (notificationKey) => {
    return NotificationsType[notificationKey] || notificationKey;
  };

  const notificationSettings = record?.notification_settings?.notify_by;

  return (
    <SimpleShowLayout>
      {notificationSettings &&
        Object.keys(notificationSettings).map((notifyByKey) => {
          const notifyByObject = notificationSettings[notifyByKey];

          if (notifyByObject && typeof notifyByObject === "object") {
            const enabledMethods = Object.keys(notifyByObject).filter(
              (key) => notifyByObject[key]
            );
            const prettyName = getPrettyNotificationName(notifyByKey);
            return (
              <React.Fragment key={notifyByKey}>
                <Typography variant="h6">
                  {`${prettyName}: [ ${enabledMethods.join(", ")} ]`}
                </Typography>
              </React.Fragment>
            );
          }

          return null; // Skip this section if notifyByObject is not an object
        })}
    </SimpleShowLayout>
  );
};

const PaymentPlansList = ({ plans, title }) => {
  if (!plans || Object.keys(plans).length === 0) {
    return <p>No {title.toLowerCase()} available.</p>;
  }

  const renderPlanDetails = (plan) => (
    <ul>
      <li>
        <strong>Pricing Tier:</strong> {plan.pricing_tier_id || "-"}
      </li>
      <li>
        <strong>Level:</strong> {plan.level || "-"}
      </li>
      <li>
        <strong>Source:</strong> {plan.source || "-"}
      </li>
      <li>
        <strong>Is Trial:</strong> {plan.is_trial ? "Yes" : "No"}
      </li>
      <li>
        <strong>Auto Renew:</strong> {plan.auto_renew ? "Yes" : "No"}
      </li>
      <li>
        <strong>Total Credits:</strong> {plan.total_credits || "-"}
      </li>
      <li>
        <strong>Allowed Audio (ms):</strong> {plan.allowed_audio_ms || "-"}
      </li>
      <li>
        <strong>Consumed Audio (ms):</strong> {plan.consumed_audio_ms || "-"}
      </li>
      <li>
        <strong>Activated Date:</strong> {plan.activated_date || "-"}
      </li>
      <li>
        <strong>Expires Date:</strong> {plan.expires_date || "-"}
      </li>
      <li>
        <strong>Features:</strong>{" "}
        {plan.feature_ids?.length > 0 ? plan.feature_ids.join(", ") : "-"}
      </li>
      <li>
        <strong>Credit Usage:</strong>
        <ul>
          {plan.credit_usage?.length > 0
            ? plan.credit_usage.map((usage, index) => (
                <li key={index}>
                  {usage.type}: {usage.amount}
                </li>
              ))
            : "-"}
        </ul>
      </li>
    </ul>
  );

  return (
    <>
      <h4>{title}</h4>
      
        {Object.entries(plans).map(([key, plan], index) => (
      <div>    
            
            {renderPlanDetails(plan)}
            </div>    
        ))}
      
    </>
  );
};


const PaymentSettings = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const [paymentPlans, setPaymentPlans] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!record?.id) return;

    const fetchPaymentPlans = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await dataProvider.getPaymentPlansForUser({
          id: record.id,
        });
        setPaymentPlans(response.data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching payment plans.");
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentPlans();
  }, [record?.id, dataProvider]);

  return (
    <SimpleShowLayout>
      {loading && <p>Loading payment plans...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && paymentPlans && (
        <>
          <PaymentPlansList
            plans={[paymentPlans.payment_plan_summary]}
            title="User's Payment Plan"
          />
          <PaymentPlansList
            plans={paymentPlans.workspace_plans}
            title="Workspace Plans"
          />
          <PaymentPlansList plans={paymentPlans.managed_plans} title="Managed Plans" />
        </>
      )}
    </SimpleShowLayout>
  );
};




const UserTitle = () => {
  const record = useRecordContext();
  return record ? <span>User "{record.id}"</span> : null;
};

const req = [required()];

const UserProfilePicture = () => {
  const record = useRecordContext();
  return record ? (
    <Avatar src={record.image_url} style={{ width: 64, height: 64 }} />
  ) : null;
};

const LinkTo = (props) => {
  const record = useRecordContext();
  const { platform, icon } = props;
  if (!platform) {
    return null;
  }

  const platforms = new Map([
    [
      "Amplitude",
      `${process.env.REACT_APP_AMPLITUDE_BASE_LINK}/search/user_id=${record?.id}`,
    ],
    [
      "Sentry",
      `${process.env.REACT_APP_SENTRY_BASE_LINK}?&query=is%3Aunresolved+user.id%3A${record?.id}&statsPeriod=14d`,
    ],
  ]);

  const linkToSelectedPlatform = platforms.get(platform);

  return record && linkToSelectedPlatform ? (
    <a
      rel="noreferrer"
      target="_blank"
      href={linkToSelectedPlatform}
      onClick={(e) => {
        // Conditionally prevent default action based on whether the link is clickable
        if (!icon) {
          return; // Allow default action
        }

        e.stopPropagation(); // Prevent default action
        // Your custom click handling logic here
      }}
    >
      {icon && platform === "Sentry" && (
        <img
          height="18px"
          width="18px"
          src="https://pxassets.s3.us-east-2.amazonaws.com/images/sentry_admin1.png"
          alt="Sentry Icon"
        />
      )}
      {icon && platform === "Amplitude" && (
        <img
          height="18px"
          width="18px"
          src="https://pxassets.s3.us-east-2.amazonaws.com/images/amp_admin1.png"
          alt="Amplitude Icon"
        />
      )}
      {!icon && platform} {/* Show the platform text if icon is not true */}
    </a>
  ) : null;
};

export const deletedRowStyle = (record, index) => ({
  backgroundColor: record.deleted_on ? "#fee" : "white",
});

// const userWorkspaceRole = (record) => {
//   const role = record.users.filter((curUser) => {
//     return record.users[curUser].role && record.users[curUser].user_id === record.id;
//   });

//   return role;
// };
export const UserWorkspaceRoleField = () => {
  const { id: userIdFromUrl } = useParams(); // Get the user ID from the URL
  const workspacesRecord = useRecordContext(); // Get the user record you're currently viewing
  if (!workspacesRecord) {
    return null; // Handle cases where data might be missing
  }

  // Find the user with the matching user_id (from URL) within the user record
  const userInRecord = workspacesRecord.users.find(
    (user) => user.user_id === userIdFromUrl
  );

  if (userInRecord) {
    return <span>{userInRecord.role}</span>;
  }

  return null; // Handle cases where there is no matching user
};
const UserWorkspaceStatusField = () => {
  const { id: userIdFromUrl } = useParams(); // Get the user ID from the URL
  const workspacesRecord = useRecordContext(); // Get the user record you're currently viewing

  if (!workspacesRecord) {
    return null; // Handle cases where data might be missing
  }

  // Filter and sort the users by `status_changed_at` in descending order
  const sortedUsers = workspacesRecord.users
    .filter((user) => user.user_id === userIdFromUrl)
    .sort(
      (a, b) => new Date(b.status_changed_at) - new Date(a.status_changed_at)
    );

  // Take the first element from the sorted array, which has the latest `status_changed_at`
  const latestUserRecord = sortedUsers[0];

  if (latestUserRecord) {
    return <span>{latestUserRecord.status}</span>;
  }

  return null; // Handle cases where there is no matching user
};

const UserWorkspaceStatusUpdatedAt = () => {
  const { id: userIdFromUrl } = useParams(); // Get the user ID from the URL
  const workspacesRecord = useRecordContext(); // Get the user record you're currently viewing

  if (!workspacesRecord) {
    return null; // Handle cases where data might be missing
  }

  // Find the user with the matching user_id (from URL) within the user record
  const userInRecord = workspacesRecord.users.find(
    (user) => user.user_id === userIdFromUrl
  );

  if (userInRecord) {
    return (
      <span>
        <Tooltip title={formatTime(userInRecord.status_changed_at)}>
          <Typography variant="body2">
            <DateField
              record={userInRecord}
              source="status_changed_at"
              label="Status Changed"
            />
          </Typography>
        </Tooltip>
      </span>
    );
  }
};

const UserChannelRoleField = ({ record }) => {
  const userInChannel = record.users.find((user) => user.user_id === record.id);

  if (userInChannel) {
    return <span>{userInChannel.role}</span>;
  }

  return null;
};

const UserChannelStatusField = ({ record }) => {
  const userInChannel = record.users.find((user) => user.user_id === record.id);

  if (userInChannel) {
    return <span>{userInChannel.status}</span>;
  }

  return null;
};

const UserChannelJoinedField = ({ record }) => {
  const userInChannel = record.users.find((user) => user.user_id === record.id);

  if (userInChannel) {
    return (
      <span>
        <Tooltip title={formatTime(userInChannel.joined_at)}>
          <Typography variant="body2">
            <DateField
              record={userInChannel}
              source="joined_at"
              label="Joined At"
            />
          </Typography>
        </Tooltip>
      </span>
    );
  }

  return null;
};

export const UserEdit = (props) => (
  <Edit title={<UserTitle />} {...props}>
    <TabbedForm>
      <FormTab label="Info">
        <Labeled source="id">
          <TextField source="id" />
        </Labeled>
        <UserProfilePicture />
        <TextInput source="first_name" fullWidth validate={req} />
        <TextInput source="last_name" fullWidth validate={req} />
        <TextInput source="image_url" fullWidth />
        <TextInput source="role" fullWidth />
        <TextInput source="plan_type" fullWidth />
        <VerifyUserButton />
        <BooleanInput source="is_allowed_through_gate" disabled />
        <Labeled label="Analytics">
          <>
            <LinkTo platform="Amplitude" icon="true" />
            <LinkTo platform="Sentry" icon="true" />
          </>
        </Labeled>
        <Typography variant="h6">Identities</Typography>
        <ArrayField source="identities">
          <Datagrid
            sx={{
              width: "100%",
            }}
          >
            <FunctionField
              render={(record) =>
                record.provider === "facebook.com" ? (
                  <FacebookIcon />
                ) : record.provider === "magiclink" ? (
                  <LinkIcon />
                ) : record.provider === "google.com" ? (
                  <GoogleIcon />
                ) : record.provider === "phone" ? (
                  <PhoneIcon />
                ) : record.provider === "Okta" ? (
                  "Okta"
                ) : record.provider === "oidc.axon" ? (
                  "OIDC Axon"
                ) : record.provider === "direct" ? (
                  "Direct"
                ) : null
              }
            />
            <AvatarField />
            <TextField source="provider_email" />
            <TextField source="provider_phone" />
            <BooleanField source="is_verified" />
          </Datagrid>
        </ArrayField>
        <TrainUserVoiceForm user_id={props.id} onClose={props.onClose} />
        <ContactUserIdsList />
      </FormTab>
      {/* Workspaces Tab */}
      <FormTab label="Workspaces">
        <ReferenceManyField
          reference="workspaces"
          target="users.user_id"
          pagination={<Pagination />}
        >
          <Datagrid
            sx={{
              width: "100%",
              "& .column-comment": {
                maxWidth: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          >
            <TextField source="id" />
            <FunctionField label="Status" render={UserWorkspaceStatusField} />
            <FunctionField
              label="Status Changed"
              render={UserWorkspaceStatusUpdatedAt}
            />
            <FunctionField label="Role" render={UserWorkspaceRoleField} />

            <CustomDateField source="deleted_on" label="Deleted On" />
            <WorkspaceLinkField />
            <CustomDateField source="created_at" label="Created At" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>

      {/* Conversations Tab*/}
      <FormTab label="Conversations">
        <ReferenceManyField
          reference="channels"
          target="users.user_id"
          pagination={<Pagination />}
        >
          <Datagrid
            sx={{
              width: "100%",
              "& .column-comment": {
                maxWidth: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          >
            <TextField source="id" />
            <TextField source="name" />
            <FunctionField
              label="# of Users"
              render={(record) => record.users.length}
            />
            {/* User role in the channel */}
            <FunctionField
              label="User Role"
              render={(record) => UserChannelRoleField({ record })}
            />

            {/* User status in the channel */}
            <FunctionField
              label="User Status"
              render={(record) => UserChannelStatusField({ record })}
            />

            {/* User joined date in the channel */}
            <FunctionField
              label="Joined At"
              render={(record) => UserChannelJoinedField({ record })}
            />
            <CustomDateField source="last_updated_at" label="Last Updated" />

            <FunctionField
              label="# of Messages"
              render={(record) => record.total_messages}
            />
            <ReferenceField
              label="Workspace"
              source="workspace_id"
              reference="workspaces"
              link={false} // Set link to false to avoid linking to the workspace details page
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField source="visibility" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>

      {/* Carbon Links Tab */}
      <FormTab label="Carbon Links">
        <ReferenceManyField
          reference="workspaces"
          filter={{ type: "webcontact" }}
          target="created_by"
          pagination={<Pagination />}
        >
          <Datagrid
            sx={{
              width: "100%",
              "& .column-comment": {
                maxWidth: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          >
            <TextField source="id" />
            <WorkspaceLinkField />
            <DateField source="created_at" showTime />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Personal Carbon Links">
        <ReferenceManyField
          reference="workspaces"
          filter={{ type: "personallink" }}
          target="created_by"
          pagination={<Pagination />}
        >
          <Datagrid
            sx={{
              width: "100%",
              "& .column-comment": {
                maxWidth: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
            rowStyle={deletedRowStyle}
          >
            <TextField source="id" />
            <WorkspaceLinkField />
            <TextField source="vanity_name" />
            <CustomDateField source="created_at" label="Created At" />
            <CustomDateField source="deleted_on" label="Deleted On" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Messages">
        <ReferenceManyField
          reference="messages"
          target="creator_id"
          pagination={<Pagination />}
        >
          <Datagrid
            sx={{
              width: "100%",
              "& .column-comment": {
                maxWidth: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
            rowStyle={deletedRowStyle}
          >
            <TextField source="id" />
            <ReferenceField source="workspace_ids" reference="workspaces">
              <WorkspaceLinkField />
            </ReferenceField>
            <ReferenceField source="channel_ids" reference="channels">
              <ChannelLinkField />
            </ReferenceField>
            <ReferenceField source="parent_message_id" reference="messages">
              <TextField source="id" />
            </ReferenceField>
            <TextField source="status" />
            <BooleanField
              source="is_deleted_by_data_retention"
              label="D Retain?"
            />
            <TextField source="audio_delivery" />
            <FunctionField
              label="Duration"
              source="duration_ms"
              render={(record) => {
                return toHHMMSS(record.duration_ms / 1000);
              }}
            />
            <DateField source="created_at" showTime />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      {/* FormTab for Notifications list */}
      <FormTab label="Notifications">
        <ReferenceManyField
          reference="notifications"
          target="user_id"
          pagination={<Pagination />}
        >
          <Datagrid
            sx={{
              width: "100%",
              "& .column-comment": {
                maxWidth: "20em",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }}
          >
            <TextField source="id" />
            <MessageLinkIdField label="Message" />
            <ChannelLinkIdField label="Conversation" />
            <CustomDateField source="created_at" label="Created At" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      {/* FormTab for Notifications Settings */}

      <FormTab label="Notification Settings">
        <NotificationSettingsList />
      </FormTab>
      <FormTab label="Payment Settings">
        <PaymentSettings />
      </FormTab>
    </TabbedForm>
  </Edit>
);
