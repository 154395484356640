import React, { useRef, useState, useEffect } from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  FilterList,
  SavedQueriesList,
  FilterListItem,
  FunctionField,
  ReferenceField,
  TextInput,
  BooleanField,
  useRecordContext,
  ReferenceManyField,
  Pagination,
  Labeled,
  Link,
} from "react-admin";
import { Card, CardContent, Box, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/MailOutline";
import CategoryIcon from "@mui/icons-material/LocalOffer";
import { UserLinkField } from "./users";
import { ChannelLinkField } from "./channels";
import { WorkspaceLinkField } from "./workspaces";
import Player from "../components/Player";
import RecreateMP3Button from "../components/RecreateMP3Button";
import RecreateWaveformButton from "../components/RecreateWaveformButton";
import MarkMessageActiveButton from "../components/MarkMessageActiveButton";
import MarkMessageDeletedButton from "../components/MarkMessageDeletedButton";

// Call from Users to link back to messages.
export const MessageLinkIdField = (props) => {
  const record = useRecordContext();
  return (
    <Link to={`/messages/${record.message_id}`}>
      <TextField label={props.label} source="message_id" />
    </Link>
  );
};

const Mp3AudioPlayerField = ({ record, audio }) => {
  const audioRef = useRef(null);
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleCanPlay = () => {
      // Audio source is available, set canPlay to true
      setCanPlay(true);
    };

    audioElement.addEventListener("canplay", handleCanPlay);

    // Clean up the event listener when the component unmounts
    return () => {
      audioElement.removeEventListener("canplay", handleCanPlay);
    };
  }, []);

  return (
    <div>
      {canPlay ? (
        <div>
          <p>
            <a
              href={`https://api.carbonvoice.app/v2/stream/${record.id}/${
                audio._id
              }/stream.mp3?pxtoken=${localStorage.getItem("pxtoken")}`}
            >
              {audio.language}
            </a>
          </p>
        </div>
      ) : (
        <p>{audio ? audio._id : "Not Found"} Not Available</p>
      )}
      <audio
        ref={audioRef}
        controls
        style={{ width: "100%" }}
        src={`https://api.carbonvoice.app/v2/stream/${record.id}/${
          audio._id
        }/stream.mp3?pxtoken=${localStorage.getItem("pxtoken")}`}
      />
    </div>
  );
};

const messageFilters = [
  <TextInput label="ID" source="_id" alwaysOn />,
  <TextInput label="Creator ID" source="creator_id" alwaysOn />,
  <TextInput label="Channel ID" source="channel_id" alwaysOn />,
];

export const MessageFilterSidebar = () => (
  <Card
    sx={{ order: -1, flex: "0 0 15em", mr: 2, mt: 8, alignSelf: "flex-start" }}
  >
    <CardContent sx={{ pt: 1 }}>
      <SavedQueriesList />
      <FilterList label="Status" icon={<MailIcon />}>
        <FilterListItem
          label="Initializing"
          value={{ status: "initializing" }}
        />
        <FilterListItem label="Offline" value={{ status: "offline" }} />
        <FilterListItem label="In-Progress" value={{ status: "inprogress" }} />
        <FilterListItem label="Processing" value={{ status: "processing" }} />
        <FilterListItem label="Active" value={{ status: "active" }} />
        <FilterListItem label="Canceled" value={{ status: "canceled" }} />
        <FilterListItem label="Deleted" value={{ status: "deleted" }} />
      </FilterList>
      <FilterList label="Audio delivery" icon={<CategoryIcon />}>
        <FilterListItem
          label="Recording"
          value={{ audio_delivery: "recording" }}
        />
        <FilterListItem
          label="Streaming"
          value={{ audio_delivery: "streaming" }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export const toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

const MessageShow = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Box>
      <Box>
        <RecreateMP3Button messageId={record.id} />
        <RecreateWaveformButton messageId={record.id} />
        <MarkMessageActiveButton messageId={record.id} />
        <MarkMessageDeletedButton messageId={record.id} />
      </Box>
      <Labeled>
        <TextField source="transcription" />
      </Labeled>
      {/* HLS Format */}
      <Labeled label="HLS Stream" sx={{ width: "100%" }}>
        <Player message={record} />
      </Labeled>

      <Labeled label="RAW opus data" sx={{ width: "100%" }}>
        <FunctionField
          render={(record) => (
            <audio
              controls
              style={{ width: "100%" }}
              src={`https://api.carbonvoice.app/v2/stream/raw/${
                record.id
              }?pxtoken=${localStorage.getItem("pxtoken")}`}
              type="audio/ogg"
            />
          )}
        />
      </Labeled>

      <Labeled label="MP3" sx={{ width: "100%" }}>
        <FunctionField
          render={(record) =>
            record.audio?.filter(a=>a.extension === 'mp3').map((audio, index) => (
              <Mp3AudioPlayerField
                key={index} // Ensure each element has a unique key
                record={record}
                audio={audio} // Pass the individual audio object
              />
            ))
          }
        />
      </Labeled>
      <Labeled label="Debugger" sx={{ width: "100%" }}>
        <FunctionField
          render={(record) => (
            <a
              href={`https://grafana.carbonvoice.app/d/a-upnh74k/message-debugger?orgId=1&var-message_id=${
                record.id
              }&from=${new Date(record.created_at).getTime()}&to=${new Date(
                record.last_updated_at
              ).getTime()}`}
              target="_blank"
              rel="noreferrer"
            >
              Grafana Message Debugger
            </a>
          )}
        />
       
      </Labeled>
      <Labeled label="Logs" sx={{ width: "100%" }}>
      <FunctionField
          render={(record) => (
            <a
              href={`https://grafana.carbonvoice.app/explore?panes={"cYG":{"datasource":"loki","queries":[{"refId":"A","expr":"{app=\\"cv-api\\"} |= \\"${record.id}\\" |~ \\"MessageService|StreamService|StreamGateway|MessageStreamingStartConsumer|TranslationService|TranscriptionService\\" != \\"listen\\" != \\"caught_up\\"\\r\\n","queryType":"range","datasource":{"type":"loki","uid":"loki"},"editorMode":"code"}],"range":{"from":"${new Date(record.created_at).getTime()-500}","to":"${new Date(
                record.last_updated_at
              ).getTime()}"}}}&schemaVersion=1&orgId=1`}
              target="_blank"
              rel="noreferrer"
            >
              Grafana Logs
            </a>
          )}
        />
      </Labeled>
      <Typography variant="subtitle1">Replies:</Typography>
      <ReferenceManyField
        reference="messages"
        target="parent_message_id"
        pagination={<Pagination />}
      >
        <Datagrid
          sx={{
            width: "100%",
            "& .column-comment": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TextField source="id" />
          <ReferenceField source="workspace_ids" reference="workspaces">
            <WorkspaceLinkField />
          </ReferenceField>
          <ReferenceField source="channel_ids" reference="channels">
            <ChannelLinkField />
          </ReferenceField>
          <ReferenceField source="parent_message_id" reference="messages">
            <TextField source="id" />
          </ReferenceField>
          <TextField source="status" />
          <TextField source="audio_delivery" />
          <FunctionField
            label="Duration"
            source="duration_ms"
            render={(record) => {
              return toHHMMSS(record.duration_ms / 1000);
            }}
          />
          <DateField source="created_at" showTime />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};

export const MessageList = (props) => (
  <List {...props} aside={<MessageFilterSidebar />} filters={messageFilters}>
    <Datagrid rowClick="expand" expand={<MessageShow />}>
      <TextField source="id" />
      <ReferenceField source="workspace_ids" reference="workspaces">
        <WorkspaceLinkField />
      </ReferenceField>
      <ReferenceField source="channel_ids" reference="channels">
        <ChannelLinkField />
      </ReferenceField>
      <ReferenceField source="creator_id" reference="users">
        <UserLinkField />
      </ReferenceField>
      <ReferenceField source="parent_message_id" reference="messages">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="status" />
      <BooleanField source="is_deleted_by_data_retention" label="D Retain?" />
      <BooleanField source="is_text_to_speech" label="Text?" />
      <TextField source="audio_delivery" />
      <FunctionField
        label="Duration"
        source="duration_ms"
        render={(record) => {
          return toHHMMSS(record.duration_ms / 1000);
        }}
      />
      <FunctionField
        label="Words"
        render={(record) => {
          return record.transcription?.split(" ").length;
        }}
      />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
