import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FilterList,
  SavedQueriesList,
  FilterListItem,
  FunctionField,
  ReferenceField,
  TextInput,
  BooleanField,
  useRecordContext,
  ReferenceManyField,
  Pagination,
  ReferenceInput,
  AutocompleteInput,
  Labeled,
} from "react-admin";
import { Card, CardContent, Box, Typography } from "@mui/material";
import ActiveUsersGraph from "../components/ActiveUsersGraph";


export const ActiveUsersGraphs = (props) => (
  
    <ActiveUsersGraph></ActiveUsersGraph>
  
);
