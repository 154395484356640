import React, { useEffect, useState } from "react";
import { BarChart, Bar, Tooltip, XAxis, YAxis } from "recharts";
import { fetchJson } from "../authProvider";
import { Box, Card, Typography } from "@mui/material";

export default function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});

  useEffect(() => {
    fetchJson("https://api.carbonvoice.app/admin/users/active").then(
      (result) => {
        const by_workspace = result.json;

        // Initialize an accumulator for all workspaces combined
        const accumulatedData = {};

        const workspaceData = by_workspace.reduce((acc, workspace) => {
          const sortedData = workspace.active_users.sort((a, b) => a.month - b.month);
          const chartData = sortedData.map((instance) => {
            // Accumulate data for the "all workspaces" entry
            if (!accumulatedData[instance.month]) {
              accumulatedData[instance.month] = 0;
            }
            accumulatedData[instance.month] += instance.active_users;

            return {
              month: instance.month,
              active: instance.active_users,
            };
          } );
          if ( [ "SCRnML44eTrx9Izk", "phononx" ].includes( workspace._id ) ) {
            acc[ workspace._id ] = chartData;
          }
          return acc;
        }, {});

        // Convert accumulatedData into an array format suitable for recharts
        const allWorkspacesData = Object.entries(accumulatedData).map(([month, active]) => ({
          month: parseInt(month),
          active,
        }));

        // Add the "all workspaces" data to the workspaceData
        workspaceData["all_workspaces"] = allWorkspacesData;

        setItems(workspaceData);
        setIsLoaded(true);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <h3>Active Users Per Month by Workspace</h3>
      {Object.entries(items).map(([workspaceId, data]) => (
        <Card key={workspaceId} style={{ marginBottom: 20 }}>
          <Typography variant="h6" style={{ padding: 16 }}>
            {workspaceId === "all_workspaces" ? "All Workspaces" : `Workspace ID: ${workspaceId}`}
          </Typography>
          <BarChart
            width={1600}
            height={300}
            margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
            data={data}
            syncId={workspaceId}
          >
            <Tooltip />
            <XAxis dataKey="month" />
            <YAxis yAxisId="left" />
            <Bar dataKey="active" fill="#cccccc" yAxisId="left" />
          </BarChart>
        </Card>
      ))}
    </Box>
  );
}
