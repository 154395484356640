import React from "react";
import { Button } from "@mui/material";
import { useMutation } from "react-query";
import { useDataProvider } from "react-admin";
import { DeleteForever } from "@mui/icons-material";

const MarkMessageActiveButton = ({ messageId }) => {
  const dataProvider = useDataProvider();

  const { mutate, isLoading } = useMutation(["markMessageActive", messageId], () =>
    dataProvider.markMessageActive({ id: messageId })
  );

  const handleClick = () => {
    const userConfirmed = window.confirm("Are you sure you want to mark this message as active?");
    if (userConfirmed) {
      mutate();
    }
  };

  return (
    <Button onClick={handleClick} disabled={isLoading}>
      Mark Active
    </Button>
  );
};

export default MarkMessageActiveButton;
